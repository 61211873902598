import React from 'react';
import { Container } from './google-form.css';

const GoogleForm = () => {
  return (
    <Container>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfYrihJdTYPoeQ5a9YlEI88ThcTAtXAJsxiioObEqzUa_ZWRg/viewform?embedded=true"
        height="2350"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Contact form"
      >
        Loading…
      </iframe>
    </Container>
  );
};

export default GoogleForm;
