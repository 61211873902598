import React from 'react';
import { Container, DownloadContainer } from './vertical-card.css';
import PropTypes from 'prop-types';

import MoonIcon from '../../images/icon-moon.svg';
import CloudMoonIcon from '../../images/icon-cloud-moon.svg';
import StarIcon from '../../images/icon-star.svg';
import BedIcon from '../../images/icon-bed.svg';
import PhoneIcon from '../../images/icon-phone.svg';
import DownloadIcon from '../../images/icon-download.svg';
import DummyIcon from '../../images/icon-dummy.svg';
import FoodIcon from '../../images/icon-food.svg';

const icons = {
  moon: MoonIcon,
  cloudMoon: CloudMoonIcon,
  star: StarIcon,
  bed: BedIcon,
  phone: PhoneIcon,
  download: DownloadIcon,
  dummy: DummyIcon,
  food: FoodIcon,
};

const VerticalCard = ({ title, icon, content, download }) => {
  const Icon = icons[icon];
  if (download) {
    return (
      <div className="my-2 px-2 w-full md:w-1/2 lg:w-1/4">
        <a href={download}>
          <DownloadContainer>
            <div className="title">{title}</div>
            {icon && (
              <div className="icon-container">
                <Icon />
              </div>
            )}
            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
          </DownloadContainer>
        </a>
      </div>
    );
  }
  return (
    <div className="my-2 px-2 w-full md:w-1/2 lg:w-1/3">
      <Container>
        <div className="title">{title}</div>
        {icon && (
          <div className="icon-container">
            <Icon />
          </div>
        )}
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </div>
  );
};

VerticalCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOf('moon'),
  content: PropTypes.string,
  download: PropTypes.string,
};

export default VerticalCard;
