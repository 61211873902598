import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import FullscreenImage from '../fullscreen-image';
import NavBar from '../nav-bar';
import SplitContent from '../split-content';
import CardGrid from '../card-grid';
import VerticalCard from '../vertical-card';
import HorizontalCard from '../horizontal-card';
import HorizontalRule from '../horizontal-rule';
import GoogleForm from '../google-form';

const BlockRenderer = ({ block }) => {
  return (
    <>
      {block.type === 'fullscreenImage' && (
        <FullscreenImage
          image={
            <Img
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={block.image.childImageSharp.fluid}
            />
          }
          logo={
            <Img
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={block.logo.childImageSharp.fluid}
            />
          }
        />
      )}
      {block.type === 'navBar' && <NavBar />}
      {block.type === 'splitContentImageText' && (
        <SplitContent
          id={block.id}
          left={
            <Img
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={block.image.childImageSharp.fluid}
            />
          }
          right={<div dangerouslySetInnerHTML={{ __html: block.text }} />}
        />
      )}
      {block.type === 'splitContentTextImage' && (
        <SplitContent
          id={block.id}
          left={<div dangerouslySetInnerHTML={{ __html: block.text }} />}
          right={
            <Img
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={block.image.childImageSharp.fluid}
            />
          }
        />
      )}
      {block.type === 'cardGrid' && (
        <CardGrid id={block.id}>
          {block.cards.map((card, index) => (
            <>
              {card.type === 'vertical' && (
                <VerticalCard key={index} title={card.title} icon={card.icon} content={card.content} />
              )}
              {card.type === 'horizontal' && (
                <HorizontalCard
                  key={index}
                  image={
                    <Img
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'cover' }}
                      fluid={card.image.childImageSharp.fluid}
                    />
                  }
                  text={card.content}
                  nameAge={card.nameAge}
                />
              )}
              {card.type === 'vertical-download' && (
                <VerticalCard key={index} title={card.title} icon={card.icon} download={card.download} />
              )}
            </>
          ))}
        </CardGrid>
      )}
      {block.type === 'horizontalRule' && <HorizontalRule text={block.text} id={block.id} />}
      {block.type === 'googleForm' && <GoogleForm />}
    </>
  );
};

BlockRenderer.propTypes = {
  block: PropTypes.object,
};

export default BlockRenderer;
