import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  ${tw`w-full relative`}
  height: 80vh;

  .logo {
    ${tw`absolute bottom-0 left-0`}
    width: 300px;
    height: auto;
  }

  ${MEDIA.TABLET`
    height: 50vh;
    .logo {
      width: 200px;
    }
  `}

  ${MEDIA.PHONE`
    .logo {
      width: 100px;
    }
  `}
`;
