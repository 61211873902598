import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './split-content.css';

const SplitContent = ({ id, left, right }) => {
  return (
    <Container id={id}>
      <div className="split">{left}</div>
      <div className="split">{right}</div>
    </Container>
  );
};

SplitContent.propTypes = {
  id: PropTypes.string,
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

export default SplitContent;
