import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`flex my-8 items-center`}
  background-color: #c4ddf2;
  min-height: 3rem;
  p {
    ${tw`text-center w-full text-3xl text-gray-700`}
  }
`;
