import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  ${tw`rounded flex flex-col items-center overflow-hidden pt-4`}
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  height: 25rem;
  .title {
    ${tw`font-bold text-2xl my-2 text-blue-900 text-center`}
  }
  .icon-container {
    ${tw`w-16 h-16`}
    svg {
      path {
        fill: black;
      }
    }
  }
  .content {
    ${tw`text-center p-4 text-xl`}
  }

  ${MEDIA.PHONE`
    height: initial;
    min-height: 25rem;
  `}
`;

export const DownloadContainer = styled.div`
  ${tw`rounded flex flex-col items-center overflow-hidden`}
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  height: 14rem;
  .title {
    ${tw`font-bold text-2xl my-2 text-blue-900 text-center`}
  }

  .icon-container {
    ${tw`w-16 h-16`}
    svg {
      path {
        fill: black;
      }
    }
  }

  .content {
    ${tw`text-center p-4 text-xl`}
  }

  &:hover {
    ${tw`bg-gray-100`}
  }
`;
