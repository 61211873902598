import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  ${tw`flex`}

  .split {
    h1 {
      ${tw`text-3xl font-bold text-blue-900`}
    }
    ${tw`w-1/2 flex justify-center items-center text-xl p-8`}
    img {
      ${tw`p-10`}
    }
  }

  ${MEDIA.TABLET`
    ${tw`flex-col`}
    .split {
      ${tw`w-full`}
      img {
        ${tw`p-0`}
      }
    }
  `}
`;
