import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  iframe {
    ${tw`w-full`}
    height: 2350px !important;
  }

  ${MEDIA.PHONE`
    iframe {
      height: 2550px !important;
    }
  `}
`;
