import React from 'react';
import { Container } from './fullscreen-image.css';
import PropTypes from 'prop-types';

const FullscreenImage = ({ image, logo }) => {
  return (
    <Container>
      {image}
      {logo && <div className="logo">{logo}</div>}
    </Container>
  );
};

FullscreenImage.propTypes = {
  image: PropTypes.node.isRequired,
  logo: PropTypes.node,
};

export default FullscreenImage;
