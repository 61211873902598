import React from 'react';
import { Container } from './card-grid.css';
import PropTypes from 'prop-types';

const CardGrid = ({ children, id }) => {
  return <Container id={id}>{children}</Container>;
};

CardGrid.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

export default CardGrid;
