import React from 'react';
import { Container } from './horizontal-rule.css';
import PropTypes from 'prop-types';

const HorizontalRule = ({ text, id }) => {
  return (
    <Container id={id}>
      <p>{text}</p>
    </Container>
  );
};

HorizontalRule.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
};

export default HorizontalRule;
