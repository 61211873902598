import React from 'react';
import { Container } from './nav-bar.css';
import { Link } from 'gatsby';

const NavBar = () => {
  return (
    <Container>
      <ul>
        <li>
          <Link to="#about">About</Link>
        </li>
        <li>
          <Link to="#packages">Packages</Link>
        </li>
        <li>
          <Link to="#parent-reviews">Parent Reviews</Link>
        </li>
        <li>
          <Link to="#contact">Contact</Link>
        </li>
        <li>
          <Link to="#downloads">Downloads</Link>
        </li>
      </ul>
    </Container>
  );
};

export default NavBar;
