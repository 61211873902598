import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  ${tw`flex justify-center`}
  background-color: #c4ddf2;
  min-height: 5rem;

  ul {
    ${tw`flex justify-between items-center text-center w-4/5`}
    li {
      ${tw`text-xl`}
    }
  }

  ${MEDIA.TABLET` 
    ul {
      ${tw`flex-col`}
      li {
        ${tw`my-2`}
      }
    }
  `}
`;
