import styled from 'styled-components';
import tw from 'twin.macro';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  ${tw`rounded flex flex-row items-center overflow-hidden`}
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  min-height: 23rem;
}

  .image {
    ${tw`m-2 w-48 h-48 mx-4`}
    img {
      border-radius: 9999px;
    }
  }
  .text {
    ${tw`w-48 italic justify-center flex w-full px-2 pb-2`}
  }
  ${MEDIA.XL_PHONE`
    ${tw`flex-col`}
    height: initial;
    min-height: 23rem;
    .image{
      ${tw`w-32 h-32`}
    } 
  `}
`;
