import React from 'react';
import { Container } from './horizontal-card.css';
import PropTypes from 'prop-types';

const HorizontalCard = ({ image, text, nameAge }) => {
  return (
    <div className="my-2 px-2 w-full xl:w-1/2">
      <Container>
        <div>
          <div className="image">{image}</div>
          <div className="text-center">{nameAge}</div>
        </div>
        <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
      </Container>
    </div>
  );
};

HorizontalCard.propTypes = {
  image: PropTypes.node,
  text: PropTypes.string,
  nameAge: PropTypes.string,
};

export default HorizontalCard;
